import { request as axios } from '@/util/request';
import { PaginationData } from '@/api/operation/customer';
const getUrl = require('baimahu/url-with-param');


async function getRights(data: string = ""){
    if(data === "") return await axios.get('/boss/vip/rights');
    return await axios.get(`/boss/vip/rights/${data}`);
}
async function putRights(id: string,data: any){
    return await axios.put(`/boss/vip/rights/${id}`,data);
}

async function putWareRights(id: string,data: any){
    return await axios.put(`/boss/vip/product/${id}`,data);
}
export {
    getRights,
    putRights,
    putWareRights
}

import { Component, Vue } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import { getRights} from '@/api/vip/rights';
import { PaginationData } from '@/api/operation/customer';
import { useVip } from '@/pinia/modules/vip/vipRightData';

@Component({
    name:'Rights',
    components: {},
})
export default class Rights extends Vue {
    is_loading: boolean = false;
    rights_data: any[] = []
    
    rightsRedact(data: any) {
        useVip().setRightsData(data)
        this.$router.push({
            path: '/vip/rights_redact',
            query: { type: data.type + '' },
        });
    }

    @changeLoading(['is_loading'])
    async created() {
        const res = await getRights()
        this.rights_data = res.data
    }
}

import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useVip = defineStore('vip', () => {
  const rightsData = ref<any>();
  function setRightsData(data: any) {
    rightsData.value = data;
  }
  return { rightsData, setRightsData };
});
